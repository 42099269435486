<template>
<!-- wait for event before it tries to display its details -->
  <div v-if="event">
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
  </div>
</template>

<script>
import EventService from '@/services/EventServices.js'
export default {
    props: ['id'], 
  data() {
    return {
      event: null,
    //   id: 123
    }
  },
  created() {
    // fetch event (by id) and set local event data
    EventService.getEvent(this.id)
      .then(response => { // wait for server API call and then...
        console.log('events:', response.data)
        this.event = response.data
      })
      .catch(error => {
        console.log('error: ', error)
    })
  }
}
</script>