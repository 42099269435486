<template>
  <h1>Events for Good</h1>
  <div class="events">
    <EventCard v-for="event in events" :key="event.id" :event="event" />
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from "@/components/EventCard.vue";
// import axios from "axios";
import EventService from '@/services/EventServices.js';

export default {
  name: "Home",
  components: {
    EventCard,
  },
  data() {
    return {
      // API call
      events: null,
    }
  },
  // with refactored code into EventServices.js
  created() {
      EventService.getEvents()
      .then(response => { // wait for server API call and then...
        console.log('events:', response.data)
        this.events = response.data
      })
      .catch(error => {
        console.log('error: ', error)
      })
    // // when component is created, we're making API call to server- with axios
    // created() {
    //   axios
    //   .get('https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3/events'
    //   )
    //   .then(response => { // wait for server API call and then...
    //     console.log('events:', response.data)
    //     this.events = response.data
    //   })
    //   .catch(error => {
    //     console.log('error: ', error)
    //   })
      // hard-coded
    // data() {
    // return {
    //   events: [
    //     {
    //       id: 5928101,
    //       category: 'animal welfare',
    //       title: 'Cat Adoption Day',
    //       description: 'Find your new feline friend at this event.',
    //       location: 'Meow Town',
    //       date: 'January 28, 2022',
    //       time: '12:00',
    //       petsAllowed: true,
    //       organizer: 'Kat Laydee'
    //     },
    //     {
    //       id: 4582797,
    //       category: 'food',
    //       title: 'Community Gardening',
    //       description: 'Join us as we tend to the community edible plants.',
    //       location: 'Flora City',
    //       date: 'March 14, 2022',
    //       time: '10:00',
    //       petsAllowed: true,
    //       organizer: 'Fern Pollin'
    //     },
    //     {
    //       id: 8419988,
    //       category: 'sustainability',
    //       title: 'Beach Cleanup',
    //       description: 'Help pick up trash along the shore.',
    //       location: 'Playa Del Carmen',
    //       date: 'July 22, 2022',
    //       time: '11:00',
    //       petsAllowed: false,
    //       organizer: 'Carey Wales'
    //     }
    //   ]
    // }
  }
};
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
